<script setup lang="ts">
import type { Shop } from '@/types/shops';
import type { RouteLocation } from 'vue-router';

const translationKeys = { designedBy: 'shopInfo.designedBy' };

withDefaults(defineProps<{
  isInProductCarousel?: boolean;
  isInProductStage?: boolean;
  openLinkInNewTab?: boolean;
  shop: Shop;
}>(), { isInProductCarousel: false,isInProductStage: false, })

const localePath = useLocalePath();

const shopLink = computed(() => localePath(
  {
    name: 'shop-shopSlug',
    params: { shopSlug: __props.shop.slug },
  } as unknown as RouteLocation,
));
</script>

<template>
  <div
    v-if="shop"
    class="group grid-cols-[auto_minmax(0,_1fr)] items-start md:grid"
    data-cy="shop-information-basic"
    :class="{
      'shop-information-basic-product-stage grid rounded-lg border border-grey-light bg-white px-0 pt-3': isInProductStage,
      'bg-grey-light !pt-6 pb-4 md:bg-white md:!py-1': isInProductCarousel,
      'grid grid-cols-[auto_minmax(0,_1fr)]': isInProductStage && isInProductCarousel
    }"
  >
    <NuxtLink
      class="row-start-1 block w-fit"
      :class="[isInProductStage && !isInProductCarousel ? 'md:ml-4' : '', isInProductStage && isInProductCarousel ? 'my-0 ml-4 mr-3' : 'mx-auto md:mr-3']"
      :target="openLinkInNewTab ? '_blank' : '_self'"
      :to="shopLink"
    >
      <ShopCoverAndLogo
        class="transition-transform duration-300 group-hover:translate-y-[-5px]"
        :class="[ isInProductStage ? 'cursor-pointer' : 'row-span-2' ]"
        :shop="shop"
      />
    </NuxtLink>
    <div
      class="max-w-full grid-cols-1"
      :class="[ isInProductStage ? 'text-left' : 'text-center md:text-left', !isInProductStage && !isInProductCarousel ? 'mt-2.5 pr-0' : 'pr-5' ]"
    >
      <span
        v-if="isInProductStage"
        class="text-xs font-normal text-grey-semidark"
        v-text="$t(translationKeys.designedBy)"
      />
      <NuxtLink
        :target="openLinkInNewTab ? '_blank' : '_self'"
        :to="shopLink"
      >
        <h2
          class="m-0 truncate text-base font-semibold transition-colors duration-300 group-hover:text-purple"
          data-cy="shop-name"
          :class="{ 'cursor-pointer': isInProductStage }"
          v-text="shop.shopName"
        />
      </NuxtLink>
      <h3
        v-if="shop.userName"
        class="m-0 truncate text-xs font-normal"
        v-text="shop.userName"
      />
      <div
        class="w-full pb-4 pt-2.5 text-sm font-normal text-grey-semidark"
        :class="[isInProductCarousel ? 'hidden ml:flex' : 'hidden' ]"
      >
        <ShopDetails
          is-in-product-carousel
          :is-in-product-stage="isInProductStage"
          :shop="shop"
        />
      </div>
    </div>
    <span
      class="gap-0.5 truncate text-xs font-normal leading-tight text-grey-semidark sm:rounded-b-lg"
      :class="[!isInProductCarousel && !isInProductStage ? 'flex justify-center md:grid' : 'grid gap-0.5', isInProductCarousel ? 'bg-white ml:hidden' : '', isInProductStage ? 'col-span-2 -mt-2 w-full justify-center bg-grey-mid pb-2.5 pt-4 text-center' : 'grid-cols-[auto_minmax(0,_1fr) col-span-1 col-start-2 mt-0.5']"
    >
      <ShopDetails
        :is-in-product-stage="isInProductStage"
        :shop="shop"
      />
    </span>
  </div>
</template>
