<script setup lang="ts">
import type { Shop } from '@/types/shops';

const { isMobile } = useAppBreakpoints();

const translationKeys = {
  designs: 'shopInfo.designs',
  sales: 'shopInfo.sales',
};

withDefaults(defineProps<{
  isInProductCarousel?: boolean;
  isInProductStage?: boolean;
  shop: Shop;
}>(), { isInProductStage: false, })

const { formatNumber } = useFormatter();

const shopLocation = computed(() => {
  const locationParts = [
    __props.shop?.city,
    __props.shop?.country,
  ].filter(Boolean);
  return locationParts.join(', ');
});
</script>

<template>
  <span
    class="row-start-1"
    v-text="`${$t(translationKeys.designs, { number: formatNumber(shop.designs) } )}&nbsp;•&nbsp;`"
  />
  <span
    class="row-start-1"
    v-text="`${$t(translationKeys.sales, { number: formatNumber(shop.sales) } )}${isInProductStage ? '&nbsp;•&nbsp;' : ''}`"
  />
  <span
    v-if="shopLocation"
    :class="[
      isInProductStage ? 'row-start-1' : 'col-span-2 row-start-2',
      { 'line-clamp-1' : !isInProductCarousel }
    ]"
    v-text="`${!isInProductStage && !isInProductCarousel && isMobile ? '&nbsp;•&nbsp;' : ''}${shopLocation}`"
  />
</template>
